import { useContext, useRef, useState } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../store';
import { logout } from '../../../../store/slices/auth.slice';
import MyAvatar from '../../../../components/MyAvatar';
import { SidebarContext } from '../../../../contexts/SidebarContext';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const store = useSelector((state: RootState) => state.store);
  const { closeSidebar } = useContext(SidebarContext);
  const { user } = useSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async () => {
    const data = await dispatch<any>(logout());
    if (data) {
      navigate('/dang-nhap');
    }
  };

  return (
    <>
      <UserBoxButton color='secondary' ref={ref} onClick={handleOpen}>
        <MyAvatar name={user.name} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant='body1'>{user.name}</UserBoxLabel>
            <UserBoxDescription variant='body2'>
              {user.email}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display='flex'>
          <MyAvatar name={user.name} />
          <UserBoxText>
            <UserBoxLabel variant='body1'>{user.name}</UserBoxLabel>
            <UserBoxDescription variant='body2'>
              {user.email}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component='nav'>
          {store.id > 0 && (
            <ListItem button to='/quan-ly/chinh-sua-cua-hang' onClick={closeSidebar} component={NavLink}>
              <InboxTwoToneIcon fontSize='small' />
              <ListItemText primary='Cửa hàng' />
            </ListItem>
          )}
          <ListItem button to='/nguoi-dung/quan-ly' component={NavLink}>
            <AccountTreeTwoToneIcon fontSize='small' />
            <ListItemText primary='Tài khoản' />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color='primary' fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Đăng xuất
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
