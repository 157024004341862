import BaseService from './base.service';

class ReportService extends BaseService {
  path = () => 'report';

  revenue(date) {
    return this.getAxiosClient().post(`${this.path()}/revenue`, { date });
  }

  staff(date, staffId) {
    return this.getAxiosClient().post(`${this.path()}/staff`, { date, staffId });
  }

  debt(data, limit, offset) {
    return this.getAxiosClient().post(`${this.path()}/debt`, { data,limit, offset  });
  }

  hour(date) {
    return this.getAxiosClient().post(`${this.path()}/hour`, { date });
  }

  get(date) {
    return this.getAxiosClient().post(`${this.path()}/get`, { date });
  }
}

export default new ReportService();
