import axiosClient from './axiosClient';
import AuthService from './services/auth.service';
import { DeviceService } from './services';
import { generateRandomString } from '../app/helper';

const deviceInfo = DeviceService.getDeviceInfo();
if (!deviceInfo && !deviceInfo?.deviceId) {
  DeviceService.updateDeviceInfo({ deviceId: generateRandomString(100), updated: false });
}

const setup = (store) => {
  axiosClient.interceptors.request.use(
    (config) => {
      const token = AuthService.getLocalAccessToken();
      if (token) {
        // console.log(token);
        config.headers['Authorization'] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosClient.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      return Promise.reject(err.response.data);
    }
  );
};

export default setup;
