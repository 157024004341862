import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthService } from '../../api/services';

const Authenticated = (props) => {
  const { children } = props;
  const urlLoginSuccess = AuthService.getUrlLoginRedirect();
  const location = useLocation();
  if (urlLoginSuccess) {
    const url = urlLoginSuccess;
    AuthService.removeUrlLoginRedirect();
    return <Navigate to={url} />;
  }
  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
