import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline, Drawer, styled, Zoom } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import useAuth from './hooks/useAuth';
import AppInit from './components/AppInit';
import React, { createContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { HorizontalSnackbarType, NotificationItem, NotificationType, VerticalSnackbarType } from './app/types';
import { default as EventDrawerBooking } from './content/Booking/EventDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store';
import { addNotification } from './store/slices/notification.slice';
import moment from 'moment-timezone';
import './App.css';

const ContentDrawer = styled(Drawer)(
  ({ theme }) => `
  @media (min-width:959.95px){
  }
`
);

const initialState = {
  showNotification: (message: string, ...args: any[]) => {
  },
  setIsDrawerBookingOpen: (isOpen: boolean) => {
  },
  setItemBookingData: (item: any) => {
  },
  onBookingComplete: () => {
  },
  setChangeDataBookingList: (change: number) => {
  },
  changeDataBookingList: 0,
  itemBookingData: undefined,

};

export const AppContext = createContext({
  ...initialState
});

function App() {
  const content = useRoutes(router);
  const { products } = useSelector((state: RootState) => state.store);
  const [isDrawerBookingOpen, setIsDrawerBookingOpen] = useState(false);
  const [changeDataBookingList, setChangeDataBookingList] = useState(0);
  const [itemBookingData, setItemBookingData] = useState(null);
  const dispatch = useDispatch();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const showNotification = (message: string, ...args: any[]) => {
    const type = args.length > 0 ? args[0] : NotificationType.SUCCESS;
    const vertical = args.length > 1 ? args[1] : VerticalSnackbarType.BOTTOM;
    const horizontal = args.length > 2 ? args[2] : HorizontalSnackbarType.CENTER;
    enqueueSnackbar(message, {
      variant: type,
      anchorOrigin: { vertical, horizontal },
      TransitionComponent: Zoom
    });
  };
  useEffect(() => {
    const channel = new BroadcastChannel('notifications');
    channel.addEventListener('message', (event) => {
      const { data } = event.data;
      if (data) {
        const notificationData = JSON.parse(data.notificationData ?? {});
        const { countCreate, dataList } = notificationData;
        if (countCreate > 0 && dataList.length > 0) {
          const notification = dataList[0] as NotificationItem;
          const message = `Có đơn đặt lịch cho khách hàng ${notification.name} \n
          Đặt lúc ${notification.open} Ngày ${moment(notification.booking_date).format('DD/MM/YYYY')}
          `;
          showNotification(message);
          dispatch(addNotification(notification));
        }

      }
    });
  }, []);

  const closeBookingDrawer = () => {
    setIsDrawerBookingOpen(false);
    setItemBookingData(null);
  };

  const onBookingComplete = () => {
    closeBookingDrawer();
    setChangeDataBookingList(changeDataBookingList + 1);
  };

  const onChangeData = () => {
    setChangeDataBookingList(changeDataBookingList + 1);
  };

  return (
    <AppContext.Provider value={{
      showNotification,
      setIsDrawerBookingOpen,
      setItemBookingData,
      onBookingComplete,
      changeDataBookingList,
      setChangeDataBookingList,
      itemBookingData,
    }}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {auth.isInitialized ? content : <AppInit />}
          <ContentDrawer
            variant='temporary'
            anchor={'right'}
            onClose={closeBookingDrawer}
            open={isDrawerBookingOpen}
            elevation={9}
          >
            {isDrawerBookingOpen && (
              <EventDrawerBooking
                itemData={itemBookingData}
                onChangeData={onChangeData}
                productList={products.filter(item => !item.parent)}
                onAddComplete={onBookingComplete}
                onCancel={closeBookingDrawer}
                onDeleteComplete={closeBookingDrawer}
                onEditComplete={closeBookingDrawer}
              />
            )}
          </ContentDrawer>
        </LocalizationProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
