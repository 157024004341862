export const formatMoney = (money, prefix = 'VNĐ') => {
  const text = money.toLocaleString('it-IT', { style: 'currency', currency: 'VND' });
  return text.replace('VND', prefix).trim();
};

export const validateNumberPhone = (_numberPhone: string) => {
  const regexMobile = /^(0|84)[2|3|7|8|9|5]([0-9]{8,9})$/;
  const regexHotline = /^(1800|1900)([0-9]{4,6})$/;
  if (_numberPhone.startsWith('1800') || _numberPhone.startsWith('1900')) {
    return _numberPhone.match(regexHotline);
  }
  return _numberPhone.match(regexMobile);
};

export const weekends = [
  { name: 'Thứ hai', day_of_week: 1 },
  { name: 'Thứ ba', day_of_week: 2 },
  { name: 'Thứ tư', day_of_week: 3 },
  { name: 'Thứ năm', day_of_week: 4 },
  { name: 'Thứ sáu', day_of_week: 5 },
  { name: 'Thứ bảy', day_of_week: 6 },
  { name: 'Chủ nhật', day_of_week: 0 }
];


export const weekendsMap = {
  1: 'Thứ hai',
  2: 'Thứ ba',
  3: 'Thứ tư',
  4: 'Thứ năm',
  5: 'Thứ sáu',
  6: 'Thứ bảy',
  0: 'Chủ nhật'
};
export const getNameDayOfWeek = (item) => {
  const data = weekends.filter((w) => w.day_of_week === item);
  if (data.length > 0) return data[0].name;
};

export const applyPagination = (dataList, page, limit) => {
  return dataList.slice(page * limit, page * limit + limit);
};


export const HOUR_ALL = new Array(24 * 12).fill(0).map((_, i) => {
  const hour = ('0' + ~~(i / 12)).slice(-2);
  const minute = ('0' + 5 * (i % 12)).slice(-2);
  return `${hour}:${minute}`;
});
export const getTotalMoneyLabel = (item) => {
  if (!item) return 0;
  const count = item.productList.reduce(
    (current, value) => current + value.price - value.price_discount,
    0
  );
  return formatMoney(count);
};

