/**
 * Environments variables declared here.
 */

const Environments = {
  API_URL: process.env.REACT_APP_API_URL ?? '',
  MAX_FILE_SIZE: parseInt(process.env.REACT_APP_MAX_FILE_SIZE) ?? 5,
  MAX_QUANTITY_IMAGE_BOOKING: parseInt(process.env.REACT_APP_MAX_QUANTITY_IMAGE_BOOKING) ?? 5,
  HOST_URL: process.env.REACT_APP_API_URL.replaceAll('/api', '') ?? '',
  IMAGE_DEFAULT: '/uploads/%s/default.png'
};

export default Environments;
// console.log('Start server with environments', Environments);
