// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import moment from 'moment-timezone';
import { DeviceService } from './api/services';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAFpSlUjWJfF_kLFCKt3WmjrXLhdX1kH9k',
  authDomain: 'datlichngay-56529.firebaseapp.com',
  projectId: 'datlichngay-56529',
  storageBucket: 'datlichngay-56529.appspot.com',
  messagingSenderId: '450778921116',
  appId: '1:450778921116:web:a4b4138e2b7ac39bd9d559',
  measurementId: 'G-RFWSPTVGN8'
};
// console.log('fe');
// console.log(moment().tz('Asia/Ho_Chi_Minh'));
// console.log(moment().tz('Asia/Ho_Chi_Minh').format('DD/MM/YYYY HH:mm:ss'));
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const requestNotificationPermission = async () => {
  // console.log('requestNotificationPermissionrequestNotificationPermissionrequestNotificationPermission');
  try {
    const permission = await Notification.requestPermission();
    // console.log('Notification permission:', permission);
    const deviceInfo = DeviceService.getDeviceInfo();
    if (permission === 'granted') {
      const token = await messaging.getToken();

      let value = { tokenFirebase: token };
      if (token !== deviceInfo.tokenFirebase) {
        // @ts-ignore
        value = { ...value, updated: false };
      }
      DeviceService.updateDeviceInfo(value);
      // console.log('Messaging token:', token);
      // Send the token to your server for sending notifications
    }
  } catch (error) {
    console.log('Error requesting notification permission:', error);
  }
};

requestNotificationPermission();

messaging.onMessage((payload) => {
  console.log('Received message:', payload);
  // Handle the incoming notification
  const messaging = firebase.messaging();
  const channel = new BroadcastChannel('notifications');
  channel.postMessage(payload);
});
