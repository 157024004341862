import BaseService from './base.service';

class VerifyService extends BaseService {
  path = () => 'verify';

  verifyCode(data) {
    return this.getAxiosClient().post(this.getLink('/verifyCode'), data);
  }
}

export default new VerifyService();
