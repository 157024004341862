import BaseService from './base.service';

class CustomerService extends BaseService {
  path = () => 'customer';

  getNameByMobile(mobile) {
    return this.getAxiosClient().post(`${this.path()}/getNameByMobile`, {
      mobile
    });
  }


  infoBooking(id) {
    return this.getAxiosClient().get(`${this.path()}/infoBooking/${id}`);
  }
}

export default new CustomerService();
