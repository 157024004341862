import { Product, ProductType } from './index';

export enum BookingStatus {
  DONE = 'done',
  CREATE = 'create',
  CANCEL = 'cancel',
  CHECKIN = 'check_in',
  PENDING = 'pending',
  UNPAID = 'unpaid',
}


export const BookingMapStatus = () => {
  const map = {};

  map[BookingStatus.CREATE] = {
    text: 'Đã tạo',
    color: 'primary',
    value: BookingStatus.CREATE
  };
  map[BookingStatus.CANCEL] = {
    text: 'Huỷ bỏ',
    color: 'error',
    value: BookingStatus.CANCEL
  };
  map[BookingStatus.CHECKIN] = {
    text: 'Đang sử dụng',
    color: 'info',
    value: BookingStatus.CHECKIN
  };
  map[BookingStatus.PENDING] = {
    text: 'Chờ',
    color: 'warning',
    value: BookingStatus.PENDING
  };
  map[BookingStatus.DONE] = {
    text: 'Hoàn thành',
    color: 'success',
    value: BookingStatus.DONE
  };
  map[BookingStatus.UNPAID] = {
    text: 'Chưa thanh toán',
    color: 'secondary',
    value: BookingStatus.UNPAID
  };
  return map;
};

export const productTypeList = [
  { label: 'Sản phẩm bán', code: ProductType.PRODUCT },
  { label: 'Dịch vụ', code: ProductType.SERVICE },
  { label: 'Liệu trình', code: ProductType.COURSE }
];

export const getLabelProductType = (code: string) => {
  const type = productTypeList.filter((item) => item.code === code);
  if (type.length > 0) {
    return type[0].label;
  }
  return null;
};

export const getProductStatus = (item: Product) => {
  return item.active ? 'Có sử dụng' : 'Không sử dụng';
};


export enum Gender {
  FEMALE = 'female',
  MALE = 'male',
  OTHER = 'other'
}

export enum NotificationViewed {
  DONE = 'done',
  CREATE = 'create'
}
