import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import AuthService from '../api/services/auth.service';
import {
  checkAuthenticated,
  isNotAuthenticated
} from '../store/slices/auth.slice';
import { cloneProduct, updateStore } from '../store/slices/store.slice';
import { setDataList } from '../store/slices/notification.slice';
import { DeviceService } from '../api/services';
import moment from 'moment-timezone';

const initialAuthState = {
  isLoggedIn: false,
  user: null,
  isInitialized: false
};

const AuthContext = createContext({
  ...initialAuthState
});

export const AuthProvider = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const { isLoggedIn, user, isInitialized } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    const initialize = async () => {
      try {
        let deviceInfo: {} = {};
        if (DeviceService.isUpdateLastTimTokenFirebase()) {
          deviceInfo = DeviceService.getDeviceInfo();
          const lastUpdateFirebase = moment().tz('Asia/Ho_Chi_Minh').toDate();
          DeviceService.updateDeviceInfo({ lastUpdateFirebase, updated: true });
        }
        const data = await AuthService.myAccount(deviceInfo);
        // @ts-ignore
        const { error, store, notificationData, productList, staffList } = data;
        if (error) {
          dispatch(isNotAuthenticated(false));
          return;
        }
        if (DeviceService.isUpdateLastTimTokenFirebase()) {
          const lastUpdateFirebase = moment().tz('Asia/Ho_Chi_Minh').toDate();
          DeviceService.updateDeviceInfo({ lastUpdateFirebase, updated: true });
        }
        if (store) {
          dispatch(updateStore({ ...store, staffList }));
        }
        if (notificationData)
          dispatch(setDataList(notificationData));
        if (productList) {
          dispatch(cloneProduct(productList));
        }
        dispatch(checkAuthenticated(data));
      } catch (err) {
        dispatch(isNotAuthenticated(false));
      }
    };

    initialize();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        isInitialized
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
