import BaseService from './base.service';

class BookingService extends BaseService {
  path = () => 'booking';


  createImageHistory(id, values) {
    const _p = this.path();
    const axiosClient = this.getAxiosClient();
    axiosClient.defaults.headers['Content-Type'] = 'multipart/form-data';
    return axiosClient
      .post(`${_p}/createImageHistory/${id}`, values)
      .then((response) => {
        axiosClient.defaults.headers['Content-Type'] = 'application/json';
        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw this.getDataError(error);
      });
  }

  changeStatus(id, status) {
    return this.getAxiosClient().post(this.getLink('/changeStatus'), {
      id,
      status
    });
  }

  getChild(id) {
    return this.getAxiosClient().post(`${this.path()}/getChild/${id}`);
  }

  getHistoryLog(id) {
    return this.getAxiosClient().post(`${this.path()}/getHistoryLog/${id}`);
  }

  getBookingToDate(date = null) {
    return this.getAxiosClient().post(this.getLink('/bookingByDay'));
  }

  getLinkDetails(item) {
    return `/quan-ly/danh-sach-lich-dat/${item.id}`;
  }
}

export default new BookingService();
