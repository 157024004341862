import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account

const UserProfile = Loader(lazy(() => import('src/content/UserProfile')));

const userRoutes = [
  {
    path: 'quan-ly',
    element: <UserProfile />
  }
];

export default userRoutes;
