import React, { useRef, useState } from 'react';

import {
  alpha,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  useTheme,
  styled,
  Tooltip,
  Typography
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Text from 'src/components/Text';

import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { useTranslation } from 'react-i18next';
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import moment from 'moment-timezone';
import useApp from '../../../../../hooks/useApp';
import { BookingService, NotificationService } from '../../../../../api/services';
import { setViewed } from '../../../../../store/slices/notification.slice';
import { NotificationViewed } from '../../../../../app/types';
import { Link, NavLink, useNavigate } from 'react-router-dom';

const BoxComposed = styled(Box)(
  () => `
  position: relative;
`
);

const BoxComposedContent = styled(Box)(
  ({ theme }) => `
  position: relative;
  z-index: 7;

  .MuiTypography-root {
      color: ${theme.palette.primary.contrastText};

      & + .MuiTypography-root {
          color: ${alpha(theme.palette.primary.contrastText, 0.7)};
      }
  }
  
`
);

const BoxComposedImage = styled(Box)(
  () => `
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  filter: grayscale(80%);
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: inherit;
`
);

const BoxComposedBg = styled(Box)(
  () => `
  position: absolute;
  left: 0;
  top: 0;
  z-index: 6;
  height: 100%;
  width: 100%;
  border-radius: inherit;
`
);
const DotLegend = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.8)};
    height: ${theme.spacing(1.8)};
    display: inline-block;
    margin-right: ${theme.spacing(0.8)};
    border: ${theme.colors.alpha.white[100]} solid 2px;
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function HeaderNotifications() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setIsDrawerBookingOpen, setItemBookingData } = useApp();

  const { countCreate, dataList } = useSelector((state: RootState) => state.notification);


  const handleOpen = () => {
    setOpen(true);
  };
  const handleItemOpen = async (item: any) => {
    handleClose();
    const bookingData = await NotificationService.get(item.id);
    // @ts-ignore
    if (bookingData && !bookingData.error) {
      if (bookingData.data) {
        console.log(bookingData);
        console.log(BookingService.getLinkDetails(bookingData.data));
        navigate(BookingService.getLinkDetails(bookingData.data));
      }
      dispatch(setViewed(item.id));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t('Thông báo')}>
        <Badge
          variant={countCreate > 0 ? 'dot' : 'standard'}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{
            '.MuiBadge-badge': {
              background: theme.colors.success.main,
              animation: '1s ease 0s infinite normal none running pulse',
              transition: `${theme.transitions.create(['all'])}`
            }
          }}
        >
          <IconButtonWrapper
            sx={{
              background: alpha(theme.colors.primary.main, 0.1),
              transition: `${theme.transitions.create(['background'])}`,
              color: theme.colors.primary.main,

              '&:hover': {
                background: alpha(theme.colors.primary.main, 0.2)
              }
            }}
            color='primary'
            ref={ref}
            onClick={handleOpen}
          >
            <NotificationsActiveTwoToneIcon fontSize='small' />
          </IconButtonWrapper>
        </Badge>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box minWidth={440} maxWidth={440} p={2}>
          <BoxComposed
            mb={2}
            sx={{
              borderRadius: `${theme.general.borderRadius}`,
              background: `${theme.colors.gradients.black1}`
            }}
          >
            <BoxComposedBg
              sx={{
                opacity: 0.3,
                background: `${theme.colors.gradients.green2}`
              }}
            />
            <BoxComposedImage
              sx={{
                opacity: 0.2,
                backgroundImage:
                  'url("/static/images/placeholders/covers/1.jpg")'
              }}
            />
            <BoxComposedContent py={3}>
              <Typography
                textAlign='center'
                sx={{
                  pb: 0.5
                }}
                variant='h4'
              >
                {t('Thông báo')}
              </Typography>
              <Typography textAlign='center' variant='subtitle2'>
                Bạn có{' '}
                <Text color='success'>
                  <b>{countCreate}</b>
                </Text>{' '}
                tin nhắn mới
              </Typography>
            </BoxComposedContent>
          </BoxComposed>
        </Box>
        <Divider />

        <Box
          sx={{
            height: 220
          }}
        >
          <Scrollbar>
            <Timeline
              sx={{
                px: 2,
                py: 1,
                m: 2
              }}
            >
              {dataList.map(item => {
                return (
                  <TimelineItem
                    key={item.table_id}
                    sx={{
                      p: 0, cursor: 'pointer'
                    }}
                    onClick={() => {
                      handleItemOpen(item);
                    }}
                  >
                    <TimelineSeparator
                      sx={{
                        position: 'relative'
                      }}
                    >
                      <TimelineDot
                        sx={{
                          marginTop: 0,
                          left: `-${theme.spacing(2.1)} !important`,
                          top: `-${theme.spacing(0.5)}`
                        }}
                        color='success'
                      >
                        <BusinessCenterTwoToneIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{
                        pl: 3,
                        pb: 4
                      }}
                    >
                      <Typography variant='h5' gutterBottom>
                        Đặt lịch mới
                        <DotLegend
                          style={{
                            animation: `pulse 1s infinite`,
                            background: `${item.viewed === NotificationViewed.DONE ? theme.colors.success.main : theme.colors.error.main}`
                          }}
                        />
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        Có đơn đặt lịch cho khách hàng {item.name}
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        Đặt lúc {item.open} Ngày {moment(item.booking_date).format('DD/MM/YYYY')}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}

            </Timeline>
          </Scrollbar>
        </Box>
        <Divider />
        <Box
          p={2}
          sx={{
            textAlign: 'center'
          }}
        >
          <Button
            to='thong-bao'
            component={NavLink}
            sx={{
              px: 2,
              py: 0.5,
              fontWeight: 'normal',
              borderRadius: 10,
              background: 'transparent',
              color: `${theme.colors.primary.main}`,
              border: `${theme.colors.primary.main} solid 2px`,
              transition: `${theme.transitions.create(['all'])}`,

              '.MuiSvgIcon-root': {
                color: `${theme.colors.primary.main}`,
                transition: `${theme.transitions.create(['color'])}`
              },

              '&:hover': {
                px: 3,
                background: `${theme.colors.primary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.primary.dark
                )}`,
                boxShadow: `${theme.colors.shadows.primary}`,

                '.MuiSvgIcon-root': {
                  color: `${theme.palette.getContrastText(
                    theme.colors.primary.dark
                  )}`
                }
              },
              '&:active': {
                boxShadow: 'none'
              }
            }}
            variant='contained'
            endIcon={<ArrowForwardTwoToneIcon />}
            color='primary'
          >
            {t('Xem tất cả')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
