import { lazy, Suspense } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account
const StoreDetail = Loader(lazy(() => import('src/content/Store/Detail')));
const StoreCreate = Loader(lazy(() => import('src/content/Store/Create')));
const StoreEdit = Loader(lazy(() => import('src/content/Store/Edit')));
const ProductIndex = Loader(lazy(() => import('src/content/Product')));
const WorkDayIndex = Loader(lazy(() => import('src/content/WorkDay')));
const StaffIndex = Loader(lazy(() => import('src/content/Staff')));
const BookingIndex = Loader(lazy(() => import('src/content/Booking')));
const BookingDetail = Loader(lazy(() => import('src/content/Booking/Detail')));
const CustomerDetail = Loader(lazy(() => import('src/content/Customer/Detail')));
const CustomerIndex = Loader(lazy(() => import('src/content/Customer')));
const NotificationIndex = Loader(lazy(() => import('src/content/Notification')));
const Reports = Loader(lazy(() => import('src/content/Reports')));
const Debt = Loader(lazy(() => import('src/content/Reports/Debt')));
const ReportsExample = Loader(lazy(() => import('src/content/ReportsExample')));

const accountRoutes = [
  {
    path: 'cua-hang',
    element: <StoreDetail />
  },
  {
    path: 'cua-hang/:bookingId',
    element: <StoreDetail />
  },
  {
    path: 'thong-bao',
    element: <NotificationIndex />
  },
  {
    path: 'bao-cao-mau',
    element: <ReportsExample />
  },
  {
    path: 'bao-cao',
    element: <Reports />
  },
  {
    path: 'cong-no',
    element: <Debt />
  },
  {
    path: 'nhan-vien',
    element: <StaffIndex />
  },
  {
    path: 'tao-cua-hang',
    element: <StoreCreate />
  },
  {
    path: 'chinh-sua-cua-hang',
    element: <StoreEdit />
  },
  {
    path: 'san-pham',
    element: <ProductIndex />
  },
  {
    path: 'ca-lam-viec',
    element: <WorkDayIndex />
  },
  {
    path: 'khach-hang/:id',
    element: <CustomerDetail />
  },
  {
    path: 'khach-hang',
    element: <CustomerIndex />
  },
  {
    path: 'danh-sach-lich-dat',
    element: <BookingIndex />
  },
  {
    path: 'danh-sach-lich-dat/:bookingId',
    element: <BookingDetail />
  }
];

export default accountRoutes;
