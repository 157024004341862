import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../../api/services/auth.service';

export const login = createAsyncThunk<{}, object, {}>(
  'auth/login',
  async (data, thunkApi) => {
    // @ts-ignore
    const { email, password, access_token } = data;
    try {
      const response = await AuthService.login(email, password, access_token);
      if (response.error) {
        const dataError = AuthService.getDataError(response);
        return thunkApi.rejectWithValue(dataError);
      }
      return response;
    } catch (e) {
      const dataError = AuthService.getDataError(e);
      return thunkApi.rejectWithValue(dataError);
    }
  }
);

export const logout = createAsyncThunk<boolean, null, {}>(
  'auth/logout',
  async (thunkApi) => {
    await AuthService.logout();
    return true;
  }
);

const initialUser = { id: 0, email: '', mobile: '', name: '', active: false };
const initialState = {
  isLoggedIn: false, user: initialUser, isInitialized: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    checkAuthenticated: (state, action) => {
      // console.log('checkAuthenticated');
      state.isLoggedIn = true;
      state.user = action.payload;
      state.isInitialized = true;
    },
    isNotAuthenticated: (state, action) => {
      state.isInitialized = true;
      state.isLoggedIn = false;
      state.user = null;
    },
    updateUser: (state, action) => {
      const payload = action?.payload ?? initialUser;
      for (const [key, value] of Object.entries(payload)) {
        if (key in initialUser && key !== 'products') {
          state.user[key] = value ? value : '';
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    });
    builder.addCase(login.pending, (state, action) => {
      state.isLoggedIn = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload['user'];
      let urlLoginSuccess = '/quan-ly/tao-cua-hang';
      // @ts-ignore
      const { store } = action.payload;
      if (store) {
        urlLoginSuccess = '/quan-ly/cua-hang';
      }
      AuthService.setUrlLoginRedirect(urlLoginSuccess);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoggedIn = false;
    });
  }
});

const { reducer, actions } = authSlice;
export const { checkAuthenticated, isNotAuthenticated, updateUser } = actions;
export default reducer;
