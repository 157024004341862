import BaseService from './base.service';
import { DeviceInfo } from '../../app/types';
import moment from 'moment-timezone';

class DeviceService extends BaseService {
  getDeviceInfo() {
    return JSON.parse(localStorage.getItem('deviceInfo')) as DeviceInfo;
  }

  updateDeviceInfo(values) {
    let deviceInfo = { ...this.getDeviceInfo(), ...values };
    localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
  }


  removeDevice() {
    localStorage.removeItem('deviceInfo');
  }

  isUpdateLastTimTokenFirebase() {
    const deviceInfo = this.getDeviceInfo();
    // console.log('deviceInfo', deviceInfo);
    if (!deviceInfo || !deviceInfo.updated) {
      return true;
    }
    const now = moment().tz('Asia/Ho_Chi_Minh');
    const lastTime = moment(deviceInfo.lastUpdateFirebase);
    const diffHour = now.diff(lastTime, 'hour');
    return diffHour > 20;
  }
}

export default new DeviceService();
