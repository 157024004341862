import * as React from 'react';
import Avatar from '@mui/material/Avatar';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  const shortNameList = name.split(' ');
  let children = '';
  if (shortNameList.length > 1) {
    children = `${shortNameList[0][0]}${shortNameList[1][0]}`;
  }
  if (shortNameList.length === 1) {
    children = `${name[0]}`;
    if (name.length > 1) {
      children = `${name[0]}${name[1]}`;
    }
  }
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: children
  };
}

interface Props {
  name: string;
}

export default function MyAvatar({ name }: Props) {
  return <Avatar variant='rounded' {...stringAvatar(name)} />;
}
