import { configureStore } from '@reduxjs/toolkit/dist';
import authReducer from './slices/auth.slice';
import storeReducer from './slices/store.slice';
import notificationReducer from './slices/notification.slice';
import createStoreReducer from './slices/createStore.slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunkMiddleware from 'redux-thunk';

const rootReducer = {
  auth: authReducer,
  store: storeReducer,
  createStore: createStoreReducer,
  notification: notificationReducer
};

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunkMiddleware],
  devTools: process.env.NODE_ENV === 'development'
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
