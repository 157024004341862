import BaseService from './base.service';
import { CreateStore } from '../../app/types';

class StoreService extends BaseService {
  path = () => 'store';

  create(values) {
    const _p = this.path();
    const axiosClient = this.getAxiosClient();
    axiosClient.defaults.headers['Content-Type'] = 'multipart/form-data';
    return axiosClient
      .post(`${_p}/create`, values)
      .then((response) => {
        // @ts-ignore
        this.setStoreId(response.id);
        axiosClient.defaults.headers['Content-Type'] = 'application/json';
        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw this.getDataError(error);
      });
  }

  changeImage(values) {
    const _p = this.path();
    const axiosClient = this.getAxiosClient();
    axiosClient.defaults.headers['Content-Type'] = 'multipart/form-data';
    return axiosClient
      .post(`${_p}/changeImage`, values)
      .then((response) => {
        // @ts-ignore
        this.setStoreId(response.id);
        axiosClient.defaults.headers['Content-Type'] = 'application/json';
        return response;
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw this.getDataError(error);
      });
  }

  changePositionCover(id, data) {
    return this.getAxiosClient().post(`${this.getLink('/changePositionCover')}/${id}`, data);
  }

  checkCreate(data) {
    return this.getAxiosClient().post(`${this.getLink('/checkCreate')}`, data);
  }

  updateGoogleToken(data) {
    return this.getAxiosClient().post(`${this.getLink('/updateGoogleToken')}`, data);
  }

  cancelGoogleToken() {
    return this.getAxiosClient().post(`${this.getLink('/cancelGoogleToken')}`);
  }

  myStore() {
    return this.getAxiosClient().post(`${this.path()}/myStore`);
  }

  infoBooking() {
    return this.getAxiosClient().post(this.getLink('/infoBooking'));
  }


  setStoreId(id: number) {
    localStorage.setItem('storeId', `${id}`);
  }

  getStoreId() {
    const storeId = localStorage.getItem('storeId');
    if (storeId)
      return parseInt(storeId);
    return 0;
  }

  removeStoreId() {
    localStorage.removeItem('storeId');
  }

  getStoreLocal(): CreateStore {
    return JSON.parse(localStorage.getItem('store')) || {};
  }

  saveStoreLocal(value, key) {
    const store = JSON.parse(localStorage.getItem('store')) || {};
    store[key] = value;
    localStorage.setItem('store', JSON.stringify(store));
  }
}

export default new StoreService();
