import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import React from 'react';

const Guest = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const store = useSelector((state: RootState) => state.store);

  if (isLoggedIn) {
    if (!store.id) {
      return <Navigate to='/quan-ly/tao-cua-hang' />;
    }
    return <Navigate to='/quan-ly/cua-hang' />;
  }

  return <>{children}</>;
};

Guest.propTypes = {
  children: PropTypes.node
};

export default Guest;
