import { BookingStatus } from './status';


export enum ProductType {
  PRODUCT = 'product',
  SERVICE = 'service',
  COURSE = 'course'
}

export interface Product {
  id: number;
  key: number;
  name: string;
  price: number;
  price_discount: number;
  nextDay: number;
  code: string;

  active: boolean;
  save: boolean;
  deleted: boolean;
  childrens: Product[];
  parent: Product;
  product: Product;
  product_course: Product;
  product_type: ProductType;
  isHiddenPrice: boolean;
}

export interface CreateStore {
  simple: {
    email: string,
    mobile: string,
    name: string,
    address: string,
    description: string,
    categoryId: number,
  },
  stepId: number,
  products: Product[],
  workDayList: number[],
  advance: {
    website: string,
    facebook_name: string,
    facebook_link: string,
    zalo_link: string,
    zalo_name: string,
    youtube_link: string,
    youtube_name: string
  }
}

export interface WorkDay {
  id: number;
  maximum_service: number;
  day_of_week: number;
  name: string;
  open: string;
  close: string;
}

export interface NotificationItem {
  id: number;
  created_at: string;
  name: string;
  mobile: string;
  open: string;
  booking_date: string;
  viewed: NotificationViewed;
  table_id: string;
  table_name: string;
}

export enum StaffType {
  ADMIN = 'admin'
}

export interface Staff {
  id: number;
  name: string;
  mobile: string;
  email: string;
  active: boolean;
  iamge: string;
  type: StaffType;
}

export interface Booking {
  id: number;
  customer: Customer;
  productList: Product[];
  name: string;
  booking_date: string;
  store: number;
  number_code: number;
  status: BookingStatus;
  workDay: WorkDay;
  staff: Staff;
}

export enum Gender {
  FEMALE = 'female',
  MALE = 'male',
  OTHER = 'other'
}

export const GenderValue = {
  female: 'Nữ',
  male: 'Nam',
  other: 'Không xác định'
};

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error'
}

export enum NotificationViewed {
  DONE = 'done',
  CREATE = 'create'
}

export enum VerticalSnackbarType {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum HorizontalSnackbarType {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

export interface Customer {
  id: number;
  gender: Gender;
  mobile: string;
  address: string;
  name: string;
  email: string;
  birthday: string;
  active: boolean;
}

export interface Staff {
  id: number;
  mobile: string;
  address: string;
  name: string;
  image: string;
  email: string;
}

export interface Store {
  id: number;
  name: string;

  mobile: string;
  image: string;
  description: string;
  website: string;
  address: string;
  facebook_name: string | '';

  facebook_link: string | '';

  zalo_link: string | '';
  zalo_name: string | '';
  code: string | '';
  youtube_link: string | '';
  youtube_name: string | '';
  categoryId: number;
  coverPosition: { position: number, parentWidth: number, clientWidth: number };
  active: boolean;
  products: Product[];
  category: Category;
  staffList: Staff[];
  is_expected_time: boolean;
  attendees_email_calendar: string;
  is_automatic_create_event_calendar: boolean;
  is_delete_event_when_booking_success: boolean;
  is_connected_google_calendar: boolean;
}

export interface Category {
  title: string;
}

export interface DeviceInfo {
  deviceId: string,
  updated: boolean,
  tokenFirebase: string,
  lastUpdateFirebase: string
}

export interface Notification {
  name: string,
  mobile: string,
  open: string,
  booking_date: string,
  viewed: string,
  table_id: number,
  table_name: string,
  count_create: string,
  count_done: string,

}
