import * as Yup from 'yup';
import { Formik } from 'formik';
import { styled } from '@mui/material/styles';

import {
  Autocomplete,
  AutocompleteValue,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  Divider,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import WorkDayService from '../../api/services/workDay.service';
import { DatePicker } from '@mui/lab';
import { phoneRegExp } from '../../validator/regExp';
import CustomerService from '../../api/services/customer.service';
import { Booking, NotificationType, Product, Staff } from '../../app/types';
import moment from 'moment-timezone';
import { BookingService, StaffService } from '../../api/services';
import { getLabelProductType } from '../../app/types/status';
import { formatMoney } from '../../common';
import useApp from '../../hooks/useApp';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { generateRandomString } from '../../app/helper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

interface CustomerOptionType {
  inputValue: string;
  name: string;
  mobile?: string;
  customer_id?: number;
}

interface WorkDayOptionType {
  id: number;
  maximum_service: number;
  open: string;
  close: string;
  label: string;
}

const filter = createFilterOptions<CustomerOptionType>();

const CardActionsWrapper = styled(Card)(
  ({ theme }) => `
     background: ${theme.colors.alpha.black[5]};
     box-shadow: none;
     margin: 0 ${theme.spacing(3)};
`
);
const ContentProduct = styled(Grid)(
  () => `
  p {margin: 0px; max-width: 230px;}
`
);

const getInitialValues = (item, productList: Product[]) => {
  const initialValue = {
    mobile: '',
    content: '',
    name: '',
    booking_date: new Date(),
    workDay: '',
    customer: '',
    staff: '',
    productList: productList.map((p) => {
      return { ...p, discount: 0, selected: false };
    })
  };
  if (item) {
    if (item.customer) {
      initialValue['mobile'] = item.customer.mobile;
      initialValue['name'] = item.customer.name;
      initialValue['customer'] = item.customer.id;
    }
    if (item.staff) {
      initialValue['staff'] = item.staff.id;
    }
    initialValue['content'] = item.content ?? '';
    if (item.booking_date) {
      initialValue['booking_date'] = item.booking_date;
    }
    initialValue['workDay'] = item.workDay?.id ?? 0;
    initialValue['productList'] = productList.map((p) => {
      let discount = 0;
      let selected = false;
      if (item.productList) {
        const productItem = item.productList.filter(
          (pItem) => pItem.product.id === p.id
        );
        if (productItem.length > 0) {
          selected = true;
          discount = productItem[0].discount;
        }
      }

      return { ...p, selected, discount };
    });
  }
  return initialValue;
};

const getSelectedItem = (itemData) => {
  if (itemData && itemData.productList) {
    return itemData.productList.map((p) => {
      return p.product.id;
    });
  }
  return [];
};

interface Props {
  onAddComplete: () => void;
  onChangeData: () => void;
  itemData: Booking;
  onCancel: () => void;
  onDeleteComplete: () => void;
  onEditComplete: () => void;
  productList: Product[];
}

const EventDrawer = ({
                       onAddComplete,
                       onCancel,
                       onChangeData,
                       onEditComplete,
                       productList,
                       itemData
                     }: Props) => {
  const { showNotification, setChangeDataBookingList, changeDataBookingList } = useApp();
  const store = useSelector((state: RootState) => state.store);
  const [selectedItems, setSelected] = useState(getSelectedItem(itemData));
  const [customerId, setCustomerId] = useState(itemData?.customer?.id ?? 0);
  const [bookingDate, setBookingDate] = useState(
    itemData && itemData.booking_date ? itemData.booking_date : moment().tz('Asia/Ho_Chi_Minh').toDate()
  );
  const [maxTime, setMaxTime] = useState(23);
  const [minTime, setMinTime] = useState(0);
  const [valueTime, setValueTime] = useState(dayjs().hour(0).startOf('hour'))
  const [customerList, setCustomerList] = useState([]);
  const [workDayDefault, setWorkDayDefault] =
    useState<AutocompleteValue<any, any, any, any>>(null);
  const [workDayList, setWorkDayList] = useState([]);
  const [staff, setStaff] =
    useState<AutocompleteValue<any, any, any, any>>(null);
  const [customer, setCustomer] =
    useState<AutocompleteValue<any, any, any, any>>(null);
  const isCreating = !itemData;
  // @ts-ignore
  const disabledCustomer = itemData?.disabledCustomer ?? false;

  const [customerName, setCustomerName] = useState('');
  const discountList = useMemo(() => {
    const array = [];
    for (let i = 0; i <= 100; i++) {
      if (i % 5 === 0) {
        array.push(i);
      }
    }
    return array;
  }, []);


  const selectedSomeItems =
    selectedItems.length > 0 && selectedItems.length < productList.length;
  const selectedAll = selectedItems.length === productList.length;

  useEffect(() => {
    const getData = async () => {
      const body = {
        name: customerName
      };
      const dataList = await CustomerService.getList(100, 0, body);
      const data = dataList.data.map((item) => {
        return {
          inputValue: item.name,
          mobile: item.mobile,
          name: item.name + ' - ' + item.mobile,
          customer_id: item.id
        };
      });
      setCustomerList(data);
    };
    getData();
    return () => {
      setCustomerList([]);
    };
  }, [customerName]);

  const handleSelectAll = (event) => {
    setSelected(event.target.checked ? productList.map((item) => item.id) : []);
  };

  const handleSelectOne = (_event, itemId) => {
    if (!selectedItems.includes(itemId)) {
      setSelected((prevSelected) => [...prevSelected, itemId]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((id) => id !== itemId));
    }
  };

  const mdShowExpectedTime = useMemo(() => {
    return store.is_expected_time ? 4 : 6;
  }, [store.is_expected_time])

  const minBookingDate = () => {
    if (!itemData) {
      return new Date();
    }
    const bookingDate = moment(itemData.booking_date);
    const now = moment().tz('Asia/Ho_Chi_Minh').startOf('day');
    if (bookingDate.isBefore(now)) {
      return null;
    }
    return new Date();
  };

  const getNewProductList = (values, item, value, field) => {
    return values.productList.map((p) => {
      const newItem = { ...p };
      if (newItem.id === item.id) {
        newItem[field] = value;
      }
      return newItem;
    });
  };
  useEffect(() => {
    if (itemData && itemData.staff) {
      setStaff(itemData.staff);
    }
    if (itemData && itemData.customer) {
      setCustomer(itemData.customer);
    }
    if (itemData && itemData.booking_date) {
      const bDate = moment(itemData.booking_date);
      setValueTime(dayjs().hour(bDate.hour()).minute(bDate.minute()));
    }

  }, [itemData]);


  useEffect(() => {
    const getDataWorkDay = async () => {
      let isAll = false;
      if (itemData && !disabledCustomer) {
        isAll = true;
      }
      const data = await WorkDayService.findByDate(bookingDate, isAll);
      const dataList = [];
      let isWorkDay = false;
      setWorkDayDefault(null);
      data.data.forEach((item) => {
        const label = WorkDayService.getName(item);
        const workDay = {
          id: item.id,
          maximum_service: item.maximum_service,
          open: item.open,
          close: item.close,
          label
        };
        dataList.push(workDay);
        if (itemData && itemData.workDay) {
          if (itemData.workDay.id === item.id) {
            setWorkDayDefault(workDay);
            const openTime = item.open.split(':')[0];
            const closeTime = item.close.split(':')[0];
            setMaxTime(parseInt(closeTime));
            setMinTime(parseInt(openTime));
            isWorkDay = true;
          }
        }
      });
      if (!isWorkDay) {
        setValueTime(null);
      }
      setWorkDayList(dataList);
    };
    getDataWorkDay();
  }, [bookingDate]);
  // @ts-ignore
  return (
    <>
      <Formik
        initialValues={getInitialValues(itemData, productList)}
        validationSchema={Yup.object().shape({
          mobile: Yup.string()
            .required('Số điện thoại bắt buộc')
            .matches(phoneRegExp, 'Số điện thoại không đúng định dạng'),
          name: Yup.string().required('Họ và tên là bắt buộc'),
          workDay: Yup.number().required('Chưa chọn ca trực'),
          staff: Yup.number().required('Chưa chọn nhân viên'),
          productList: Yup.array().min(1, 'Chưa chọn sản phẩm')
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            if (selectedItems.length === 0) {
              return showNotification('Chưa chọn sản phẩm!', NotificationType.ERROR);
            }
            values['id'] = 0;
            if (itemData) {
              // @ts-ignore
              values['id'] = itemData.id ?? 0;
            }
            for (const p of values.productList) {
              if (p.selected && (p.discount < 0 || p.discount > 100)) {
                return showNotification('Giảm giá từ 0 đến 100!', NotificationType.ERROR);
              }
            }
            const productData = {};
            for (const product of values.productList) {
              if (selectedItems.includes(product.id)) {
                productData[product.id] = product.discount;
              }
            }
            const expected_time = valueTime.format('HH:mm');

            const data = { ...values, customer: customerId, productData, expected_time };
            delete data.productList;
            const dataResponse = await BookingService.create(data);
            // @ts-ignore
            if (dataResponse.error) {
              // @ts-ignore
              const { field, message } = dataResponse;
              if (field) {
                const error = {};
                error[field] = message;
                setErrors(error);
              } else {
                showNotification(message, NotificationType.ERROR);
              }
              return;
            }
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);

            onAddComplete();
            let message = 'Bạn đã tạo đơn đặt lịch thành công';
            if (!isCreating) {
              message = 'Bạn đã chỉnh sửa đơn đặt lịch thành công';
            }
            showNotification(message, NotificationType.SUCCESS);
            // setChangeDataBookingList(changeDataBookingList + 1);
          } catch (err) {
            console.error(err);
            // setErrors({ close: err.message });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            touched,
            values
          }) => (
          <form onSubmit={handleSubmit}>
            <Box p={3}>
              <Typography variant='h4'>
                {isCreating ? 'Tạo đặt lịch mới' : 'Chỉnh sửa đặt lịch'}
              </Typography>
            </Box>
            <Divider />
            <Box px={3} py={2}>
              <Autocomplete
                disableClearable
                options={customerList as CustomerOptionType[]}
                value={customer}
                disabled={disabledCustomer}

                onInputChange={(event, newValue) => {
                  // console.log('newValue on input', newValue);
                  setCustomerName(newValue);
                }}
                onChange={(event, newValue: CustomerOptionType[]) => {
                  let name = '';
                  if (typeof newValue === 'string') {
                    setCustomer({
                      name: newValue
                    });
                    name = newValue;
                  } else if (newValue) {
                    // @ts-ignore
                    const { inputValue, mobile } = newValue;
                    setCustomer({
                      name: inputValue
                    });
                    name = inputValue;
                    setFieldValue('mobile', '');
                    if (mobile) {
                      setFieldValue('mobile', mobile);
                    }
                  }
                  setFieldValue('name', name);
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.title);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `Thêm mới "${inputValue}"`
                    });
                  }

                  return filtered;
                }}
                getOptionLabel={(option: CustomerOptionType) => option.name}
                renderInput={(params) => (
                  <TextField
                    margin='normal'
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    name='name'
                    {...params}
                    label='Tên khách hàng'
                  />
                )}
              />

              <TextField
                fullWidth
                label='Số điện thoại'
                name='mobile'
                margin='normal'
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
                onChange={(event) => {
                  setFieldValue('mobile', event.target.value);
                }}
                disabled={disabledCustomer}
                value={values.mobile}
                variant='outlined'
              />
              <Autocomplete
                disableClearable
                options={store.staffList.filter(item => item.active) as Staff[]}
                value={staff}
                onChange={(e, value) => {
                  // @ts-ignore
                  const { id } = value;
                  setFieldValue('staff', id);
                  setStaff(value);
                }}

                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Grid container spacing={2}>
                      <Grid item sm={2}>
                        <Avatar
                          src={StaffService.getImageFull(option.image)}
                        />
                      </Grid>
                      <ContentProduct item sm={10}>
                        <b>{option.name}</b>
                      </ContentProduct>
                    </Grid>
                  </Box>
                )}
                getOptionLabel={(option: Staff) => option.name}
                renderInput={(params) => (
                  <TextField
                    margin='normal'
                    fullWidth
                    error={Boolean(touched.staff && errors.staff)}
                    helperText={touched.staff && errors.staff}
                    name='staff'
                    {...params}
                    label='Chọn nhân viên'
                  />
                )}
              />

              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='stretch'
                spacing={3}
                py={2}
              >
                <Grid item md={store.is_expected_time ? 4 : 6} xs={12}>
                  <DatePicker
                    label='Chọn ngày'
                    minDate={minBookingDate()}
                    inputFormat='dd/MM/yyyy'
                    value={values.booking_date}
                    onChange={(newValue) => {
                      const dateChange = moment(newValue)
                        .tz('Asia/Ho_Chi_Minh')
                        .toDate();
                      setBookingDate(dateChange);
                      setFieldValue('booking_date', dateChange);
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        margin='normal'
                        sx={{m: 0}}
                        type='text'
                        error={Boolean(
                          touched.booking_date && errors.booking_date
                        )}
                        helperText={touched.booking_date && errors.booking_date}
                        {...params}
                      />
                    )}
                  />
                </Grid>


                <Grid item md={store.is_expected_time ? 4 : 6} xs={12}>
                  <Autocomplete
                    disableClearable
                    options={workDayList}
                    value={workDayDefault}
                    onChange={(e, value: WorkDayOptionType[]) => {
                      // @ts-ignore
                      const { id,open, close} = value;
                      setFieldValue('workDay', id);
                      const openTime = open.split(':')[0];
                      const closeTime = close.split(':')[0];
                      setMaxTime(parseInt(closeTime));
                      setMinTime(parseInt(openTime));
                      setValueTime(dayjs().hour(parseInt(openTime)).startOf('hour'));
                      setWorkDayDefault(value);
                    }}
                    getOptionLabel={(option: WorkDayOptionType  ) => option.label}
                    renderInput={(params) => (
                      <TextField
                        margin='normal'
                        fullWidth
                        sx={{m: 0}}
                        error={Boolean(touched.workDay && errors.workDay)}
                        helperText={touched.workDay && errors.workDay}
                        name='workDay'
                        {...params}
                        label='Chọn ca trực'
                      />
                    )}
                  />
                </Grid>

                {store.is_expected_time && (
                  <Grid item md={4} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker label="Thời gian dự kiến"  ampm={false}
                                  onChange={(newValue) => {
                                    console.log(newValue);
                                    setValueTime(newValue);
                                  }}
                                  value={valueTime}
                                  minTime={dayjs().hour(minTime).startOf('hour')}
                                  maxTime={dayjs().hour(maxTime).startOf('hour')}
                      />
                    </LocalizationProvider>
                  </Grid>
                )}
              </Grid>



              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      <TableCell padding='checkbox'>
                        <Checkbox
                          checked={selectedAll}
                          indeterminate={selectedSomeItems}
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell>Tên</TableCell>
                      <TableCell sx={{ display: { xs: 'none', sm: 'block' } }}>Loại</TableCell>
                      <TableCell>Giá</TableCell>
                      <TableCell align='right'>Giảm (%)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.productList.filter(item => item.active).map((item) => {
                      const isItemSelected = selectedItems.includes(item.id);
                      return (
                        <TableRow hover key={generateRandomString(10)} selected={isItemSelected}>
                          <TableCell padding='checkbox'>
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => {
                                const value = event.target.checked;
                                setFieldValue(
                                  'productList',
                                  getNewProductList(
                                    values,
                                    item,
                                    value,
                                    'selected'
                                  )
                                );
                                handleSelectOne(event, item.id);
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography variant='h5'>{item.name}</Typography>

                            <Typography sx={{ display: { xs: 'table-cell', sm: 'none' } }}>
                              {getLabelProductType(item.product_type)}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                            <Typography>
                              {getLabelProductType(item.product_type)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{formatMoney(item.price ?? 0)}</Typography>
                          </TableCell>
                          <TableCell>
                            <Select
                              value={item.discount}
                              onChange={(event) => {
                                const value = event.target.value;
                                setFieldValue(
                                  'productList',
                                  getNewProductList(
                                    values,
                                    item,
                                    value,
                                    'discount'
                                  )
                                );
                              }}
                              MenuProps={{
                                PopoverClasses: {
                                  root: 'NoBackdrop'
                                }
                              }}
                            >
                              {discountList.map((discount) => (
                                  <MenuItem key={discount} value={discount}>
                                    {discount}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TextField
                error={Boolean(touched.content && errors.content)}
                fullWidth
                multiline
                minRows={3}
                helperText={touched.content && errors.content}
                label='Yêu cầu thêm'
                name='content'
                margin='normal'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.content}
                variant='outlined'
              />
            </Box>
            <CardActionsWrapper
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2
              }}
            >
              <Box>
              </Box>
              <Box>
                <Button
                  variant='outlined'
                  sx={{
                    mr: 1
                  }}
                  color='secondary'
                  onClick={onCancel}
                >
                  Huỷ
                </Button>
                <Button
                  variant='contained'
                  type='submit'
                  startIcon={
                    isSubmitting ? <CircularProgress size='1rem' /> : null
                  }
                  disabled={isSubmitting}
                  color='primary'
                >
                  {isCreating ? 'Tạo mới' : 'Chỉnh sửa'}
                </Button>
              </Box>
            </CardActionsWrapper>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EventDrawer;
