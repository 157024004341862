import axiosClient from '../axiosClient';
import Environments from '../../common/EnvVars';
import moment from 'moment-timezone';

export default class BaseService {
  path = () => '';

  getLink = (url: string) => `${this.path()}${url}`;

  getAxiosClient = () => axiosClient;

  getList(limit = 0, offset = 0, data = {}) {
    let url = this.getLink('/list');
    if (limit !== 0 || offset !== 0) {
      url += `?limit=${limit}&offset=${offset}`;
    }
    return this.getAxiosClient().post(url, data);
  }

  create(data) {
    return this.getAxiosClient().post(`${this.path()}/create`, data);
  }

  delete(id) {
    return this.getAxiosClient().delete(`${this.path()}/delete/${id}`);
  }

  deleteMany(ids: number[]) {
    return this.getAxiosClient().post(`${this.path()}/deleteMany`, { ids });
  }

  get(id) {
    return this.getAxiosClient().post(`${this.path()}/get/${id}`);
  }


  edit(data, id = 0) {
    const link = id > 0 ? `${this.path()}/edit/${id}` : this.getLink('/edit');
    return this.getAxiosClient().post(link, data);
  }


  changeActive(values) {
    return this.getAxiosClient().post(`${this.path()}/changeActive`, values);
  }

  post(link: string, values) {
    return this.getAxiosClient().post(`${this.path()}/${link}`, values);
  }

  getDataError = (error) => {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return error;
  };

  getImageFull = (url) => {
    let newUrl = url ? url : Environments.IMAGE_DEFAULT.replaceAll('%s', this.path);
    return `${Environments.HOST_URL}/${newUrl}`;
  };

  getLocalRefreshToken() {
    const user = this.getUser();
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = this.getUser();
    return user?.accessToken;
  }

  updateLocalAccessToken(token) {
    let user = this.getUser();
    user.accessToken = token;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem('user');
  }

  convertToHour = (date) => {
    if (date) {
      return moment(date).format('HH');
    }
    return null;
  };

  convertToDate = (date) => {
    if (date) {
      return moment(date).format('DD/MM/YYYY');
    }
    return null;
  };

  convertToDateTime = (date) => {
    if (date) {
      return moment(date).format('HH:mm DD/MM/YYYY');
    }
    return null;
  };
}
