import { createSlice } from '@reduxjs/toolkit';
import { NotificationType, NotificationViewed } from '../../app/types';

const initial = {
  countDone: 0,
  countCreate: 0,
  dataList: []
};

const store = createSlice({
  name: 'notification',
  initialState: initial,
  reducers: {
    setDataList: (state, action) => {
      state.countCreate = Number(action.payload.countCreate);
      state.countDone = Number(action.payload.countDone);
      state.dataList = action.payload.dataList;
    },
    setViewed: (state, action) => {
      state.countCreate -= 1;
      if (state.countCreate < 0) state.countCreate = 0;
      // console.log('setViewed', action.payload);
      for (const item of state.dataList) {
        if (item.id === action.payload) {
          item.viewed = NotificationViewed.DONE;
        }
      }
    },
    addNotification: (state, action) => {
      state.countCreate++;
      state.dataList.unshift(action.payload);
    }
  }
});

const { reducer, actions } = store;
export const { setDataList, setViewed, addNotification } = actions;
export default reducer;
