import { createSlice } from '@reduxjs/toolkit';
import { Category, Product, Store } from '../../app/types';

const initial = {
  id: 0,
  name: '',
  mobile: '',
  email: '',
  address: '',
  active: false,
  products: [] as Product[],
  website: '',
  facebook_name: '',
  facebook_link: '',
  zalo_link: '',
  zalo_name: '',
  youtube_link: '',
  youtube_name: '',
  categoryId: 0,
  image: '',
  code: '',
  qrcode: {},
  description: '',
  category: {} as Category,
  coverPosition: { position: 0, parentWidth: 0, clientWidth: 0 },
  staffList: [],
  is_expected_time: false,
  is_connected_google_calendar: false,
  attendees_email_calendar: '',
  is_automatic_create_event_calendar: false,
  is_delete_event_when_booking_success: false,
} as Store;

const store = createSlice({
  name: 'store',
  initialState: initial,
  reducers: {
    addProduct: (state, action) => {
      state.products.push(action.payload);
    },
    cloneProduct: (state, action) => {
      state.products = action.payload;
    },
    updateProduct: (state, action) => {
      const newProduct = action.payload;
      const index = state.products.findIndex((item) => item.key === newProduct.key);
      if (index >= 0) {
        state.products[index] = newProduct;
      }
    },
    updateProductById: (state, action) => {
      const { id, ...value } = action.payload;
      const index = state.products.findIndex((item) => item.id === id);
      console.log('updateProductById', index, id, value);
      if (index >= 0) {
        state.products[index] = { ...state.products[index], ...value };
      }
    },
    removeProductByKey: (state, action) => {
      const { id, ...value } = action.payload;
      state.products = state.products.filter((item) => item.key !== id);
    },
    removeProductById: (state, action) => {
      const { id, ...value } = action.payload;
      state.products = state.products.filter((item) => item.id !== id);
    },

    cloneStaff: (state, action) => {
      state.staffList = action.payload;
    },
    addStaff: (state, action) => {
      state.staffList.push(action.payload);
    },
    updateStaff: (state, action) => {
      const { id, ...value } = action.payload;
      const index = state.staffList.findIndex((item) => item.id === id);
      if (index >= 0) {
        state.staffList[index] = { ...state.staffList[index], ...value };
      }
    },
    updateStore: (state, action) => {
      const payload = action?.payload ?? initial;
      for (const [key, value] of Object.entries(payload)) {
        if (key in initial && key !== 'products') {
          state[key] = value ? value : '';
        }
      }
    }
  }
});

const { reducer, actions } = store;
export const {
  addProduct,
  updateProduct,
  updateProductById,
  removeProductByKey,
  removeProductById,
  updateStore,
  cloneProduct,
  updateStaff, cloneStaff,
  addStaff
} = actions;
export default reducer;
