import Authenticated from 'src/components/Authenticated';
import BaseLayout from 'src/layouts/BaseLayout';
import accountRoutes from './account';
import storeRoutes from './store';
import SidebarLayout from '../layouts/SidebarLayout';
import userRoutes from './user';
import { lazy, Suspense } from 'react';
import SuspenseLoader from '../components/SuspenseLoader';

export const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const ReportsPrint = Loader(lazy(() => import('src/content/Booking/Detail/ContentReport')));

const router = [
  {
    path: '',
    element: <BaseLayout />,
    children: accountRoutes
  },
  {
    path: 'print-report',
    element: <ReportsPrint />
  },

  {
    path: 'quan-ly',
    element: (
      <Authenticated>
        <SidebarLayout />
      </Authenticated>
    ),
    children: storeRoutes
  },

  {
    path: 'nguoi-dung',
    element: (
      <Authenticated>
        <SidebarLayout />
      </Authenticated>
    ),
    children: userRoutes
  }
];

export default router;
