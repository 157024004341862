import { Box, Card, styled, Typography, useTheme } from '@mui/material';

const FooterWrapper = styled(Card)(
  ({ theme }) => `
        border-radius: 0;
        margin-top: ${theme.spacing(4)};
    width: 100%;
`
);
function Footer() {
  const theme = useTheme();
  return (
    <FooterWrapper className='footer-wrapper'>
      <Box
        p={4}
        display={{ xs: 'block', md: 'flex' }}
        alignItems='center'
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent='space-between'
        marginLeft={{ xs: 0, lg: theme.sidebar.width }}
        width={{ xs: '100%', lg: `calc(100% - ${theme.sidebar.width})` }}
      >
        <Box>
          <Typography variant='subtitle1'>
            &copy; 2022 - Hệ thống hỗ trợ đặt lịch
          </Typography>
        </Box>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
